import React from "react";
import footerLogo from "../assets/images/footer-logo.svg"
import Link from "gatsby-link";

const Footer = () => {

  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row columns-8">
          <div className="footer__logo">
            <img loading="eager" src={footerLogo} alt="Derave Software Logo"/>
          </div>
          <div className="footer__copyright">© Copyright Derave Software {new Date().getFullYear()}</div>
          <div className="footer__policy">
            <Link to="/privacy-policy">Privacy policy</Link>
          </div>
          <div className="footer__careers">
            <Link to="/careers">Careers</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
