import React from "react"
import {useStaticQuery, graphql} from "gatsby"

import CookieBar from "../components/cookie-bar"

import "../scss/main.scss"
import Contact from "./contact";
import Footer from "./footer";
import HeaderMain from "./headerMain";
import MobileMenuMain from "./mobile-menuMain";
import Newsletter from "./newsletter";

const Layout = ({isHomePage, children, hideNewsletter}) => {
  const {
    wp: {
      generalSettings: {title},
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  return (
    <div data-is-root-path={isHomePage}>
      <div className="dot"/>
      {!isHomePage && <HeaderMain/>}
      <main>{children}</main>
      {!isHomePage && !hideNewsletter && <Newsletter/>}
      {!isHomePage && <Contact/>}
      <Footer/>
      <CookieBar/>
      {!isHomePage && <MobileMenuMain/>}
    </div>
  )
}

export default Layout
