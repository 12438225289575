import React from "react";

class CookieBar extends React.Component {

  render() {
    return (
      <div id="cookie-bar" className="cookie">
        <div className="container-fluid">
          <div className="row columns-8">
            <div className="cookie__text">
              This website use cookies. Read our <a title="Privacy policy" href="/privacy-policy">privacy
              policy</a>
            </div>
            <button id="confirm-cookie" className="btn-primary">OK</button>
          </div>
        </div>
      </div>
    );
  }
}

export default CookieBar
