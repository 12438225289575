import React from "react"
import addToMailchimp from 'gatsby-plugin-mailchimp'
import parse from "html-react-parser";

class Newsletter extends React.Component {
  state = {
    email: "",
    status: "",
    resultMessage: ""
  }

  _handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(this.state.email)
    this.setState({
      resultMessage: result.msg,
      status: result.result
    })
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  render() {
    const messageClass = this.state.status === 'success' ? 'newsletter__message' : 'newsletter__message is-error'

    return (
      <section className="newsletter">
        <div className="container-fluid">
          <div className="row columns-8">
            <div className="newsletter__container">
              <form
                method="post"
                name="mc-embedded-subscribe-form"
                className=""
                target="_blank"
                noValidate
                onSubmit={this._handleSubmit}
              >
                <div className="row columns-8">
                  <div className="newsletter__text">
                    <span className="newsletter__title">Sign up for our newsletter</span>
                  </div>

                  <div className="newsletter__email">
                    <input type="email" placeholder="Your email address" name="email"
                           className="required email"
                           aria-label="Your email address"
                           id="mce-EMAIL"
                           value={this.state.email}
                           required
                           onChange={this.handleInputChange}
                    />
                  </div>

                  <div className="newsletter__button">
                    <input type="submit" value="Subscribe" name="subscribe"
                           id="mc-embedded-subscribe"
                           className="btn-primary newsletter__submit"/>
                  </div>
                  <div className={messageClass}>{parse(this.state.resultMessage)}</div>
                </div>
              </form>

            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Newsletter
