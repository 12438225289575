import React from "react";
import {Link} from "gatsby";
import Derave from "../assets/images/logo-white.svg";
import Close from "../assets/images/close_24px.svg";

class MobileMenuMain extends React.Component {

  render() {

    return (
      <div className="js-mobile-menu">
        <div className="mobile-menu">

          <div className="mobile-menu__top">
            <a href="/" className="mobile-menu__logo">
              <img loading="eager" src={Derave} alt="Derave Software Logo"/>
            </a>
            <div className="mobile-menu__close js-close">
              <img loading="eager" src={Close} alt="Close menu"/>
            </div>
          </div>
          <div className="mobile-menu__center">
            <nav className="mobile-menu__list">
              <ul>
                <li className={`mobile-menu__item`}>
                  <Link to="/#what-we-do" title="What we do">What we do</Link>
                </li>
                <li className={`mobile-menu__item`}>
                  <Link to="/#how-we-work" title="How we work">How we work</Link>
                </li>
                <li className={`mobile-menu__item`}>
                  <Link to="/#who-we-are" title="Who we are">Who we are</Link>
                </li>
                <li className={`mobile-menu__item`}>
                  <Link activeClassName="is-current" title="Blog" to="/blog">Blog</Link>
                </li>
                <li className={`mobile-menu__item`}>
                  <Link activeClassName="is-current" title="Careers" to="/careers">Careers</Link>
                </li>
              </ul>
            </nav>
            <div className="mobile-menu__line"></div>
            {/*<div className="mobile-menu__lang">
            <div className="mobile-lang">
              <div className="mobile-lang__item">
                <a href="#">English</a>
              </div>
              <div className="mobile-lang__item">
                <a href="#">Polish</a>
              </div>
            </div>
          </div>*/}
            <div className="mobile-menu__button">
              <a href="#contact" className="btn-primary">Get in touch</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MobileMenuMain
