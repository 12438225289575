import React from "react";
import Link from "gatsby-link";

class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;

    return (
      <section id="contact" className="contact">

        <div className="container-fluid">
          <div className="row columns-8">
            <div className="contact__marque">Get in touch</div>
          </div>
        </div>

        <div className="container-wrapper">

          <div className="container-fluid">
            <div className="row columns-8">

              <div className="contact-info__bar"/>

              <div className="contact-info__container">

                <div className="contact-info__block">
                  <div>
                    <a href="tel:+48690253147" className="contact-info__item">+48 690 253 147</a>
                  </div>
                  <div>
                    <a href="mailto:contact@deravesoftware.com"
                       className="contact-info__item">contact@deravesoftware.com</a>
                  </div>
                </div>

                <div className="contact-info__separator"/>

                <div className="contact-info__block">
                  <div className="contact-info__name">Derave Software Sp. z o.o.</div>
                  <div className="contact-info__address-line">ul. Tomasza Zana 41</div>
                  <div className="contact-info__address-line">20-601 Lublin</div>
                  <div className="contact-info__address-line">Poland</div>
                </div>

                <div className="contact-info__separator"/>

                <div className="contact-info__block">
                  <div className="contact-info__detail">
                    Company capital: 20000 PLN
                  </div>
                  <div className="contact-info__detail">
                    NIP 712 340 66 31<br/>
                    REGON 386935651<br/>
                    KRS 0000957429
                  </div>
                  <div className="contact-info__detail">
                    mBank<br/>
                    04 1140 2004 0000 3402 8040 2748
                  </div>
                </div>

              </div>

              <div className="contact-form">
                <form className="contact-form" id="contact-form"
                      onSubmit={this.submitForm}
                      action="https://formspree.io/f/mpzobgrr"
                      method="POST">

                  <div className="contact-form__title">Contact form</div>

                  {status === "SUCCESS" && <span className="contact-form__alert">Thank you! Message has been sent.</span>}
                  {status === "ERROR" && <span className="contact-form__alert">Ooops! There was an error.</span>}

                  <input className="contact-form__input" type="text" name="name" id="form_name" defaultValue=""
                         placeholder="Name" required aria-label="Name"/>

                  <input className="contact-form__input" type="email" name="email" id="form_email"
                         defaultValue="" placeholder="Email" required aria-label="Email"/>

                  <textarea className="contact-form__input" name="message" id="form_message" rows="5"
                            placeholder="Message (optional)" aria-label="Message (optional)"/>

                  <div className="contact-form__detail">
                    We care about your privacy. By submitting this form you automatically agree to the following&nbsp;
                    <Link target="_blank" to="/privacy-policy">Privacy Policy</Link>.
                  </div>

                  <input aria-label="Agree" type="checkbox" name="terms" className="contact-form__terms"/>
                  <div className="contact-form__btn">
                    <button type="submit" className="btn-primary">Send message</button>
                  </div>

                </form>


              </div>

            </div>
          </div>
        </div>
      </section>
    )
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
        ev.target.reset();
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}

export default Contact
